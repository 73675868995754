// Styles template
// import 'jquery-ui/themes/base/sortable.css'
// bootstrap => customisez bootstrap directement depuis un fichier custom et sélectionnez les modules communs utilisés sur toutes les pages du site au lieu de tout importer
// import 'bootstrap/scss/bootstrap'
import './scss/bootstrap'
// import 'plugins/font-awesome/5.0/css/fontawesome-all.min.css'
import 'plugins/animate/animate.min.css'
import 'scss/material/styles'
import './scss/theme'
import '@fortawesome/fontawesome-free/scss/fontawesome'
import '@fortawesome/fontawesome-free/scss/regular'
import '@fortawesome/fontawesome-free/scss/solid'

// Styles librairies
import 'plugins/gritter/css/jquery.gritter.css'

// Styles custom
import './scss/custom'

// lazy loading => https://github.com/aFarkas/lazysizes
import lazySizes from 'lazysizes'

// Plugins template
import 'plugins/pace/pace.min.js'

// Scripts librairies
// import 'jquery-ui/ui/widgets/sortable' // jquery ui : tri
import 'bootstrap'
import 'plugins/slimscroll/jquery.slimscroll.js'
import 'js/theme/material.js'
import { App } from 'js/apps.js'
import 'plugins/gritter/js/jquery.gritter.js'
import 'script-loader!domurl/url.min.js'

// Common
import 'jquery-validation/dist/jquery.validate.js'
import 'jquery-validation/dist/additional-methods.js'
import { _pageLoading } from './js/libs/misc.js'

// Scripts librairies
require('webpack-jquery-ui/sortable')
require('jquery-ui/themes/base/core.css')
require('jquery-ui/themes/base/sortable.css')
require('jquery-ui/themes/base/theme.css')
lazySizes.cfg.expand = 80
lazySizes.cfg.expFactor = 1
$('body').removeClass('no-js')

// Modal de création de convention
function getCheckCovenantInformationsModal () {
  _pageLoading()
  import('./js/components/checkCovenantInformationsModal.js').then(module => {
    module.checkCovenantInformationsModal.init()
    _pageLoading(false)
  })
}

// Formulaire d'enregistrement
function getRegisterForm () {
  _pageLoading()
  import('./js/components/registerForm.js').then(module => {
    module.registerForm.init()
    _pageLoading(false)
  })
}

// Formulaire de connexion
function getLoginForm () {
  _pageLoading()
  import('./js/components/loginForm.js').then(module => {
    const form = module.loginForm
    form.init()
    _pageLoading(false)
  })
}

// Formulaire de restauration
function getRestoreForm () {
  _pageLoading()
  import('./js/components/restoreForm.js').then(module => {
    const form = module.restoreForm
    form.init()
    _pageLoading(false)
  })
}

// Formulaire de modification du mdp
function getUpdatePwdForm () {
  _pageLoading()
  import('./js/components/updatePwdForm.js').then(module => {
    const form = module.updatePwdForm
    form.init()
    _pageLoading(false)
  })
}

// Actions principales communes à toutes les pages
$(document).ready(function () {
  App.init()

  if ($('#register-form').length) { getRegisterForm() }
  if ($('#login-form').length) { getLoginForm() }
  if ($('#restore-form').length) { getRestoreForm() }
  if ($('#update-pwd-form').length) { getUpdatePwdForm() }
  if ($('#checkCovenantInformationsModal').length) { getCheckCovenantInformationsModal() }
})
